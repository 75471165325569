/* removing error of iframe */
.unread-row {
  background-color: #baebc425; /* Couleur de fond pour les lignes non lues */
  /* Autres styles personnalisés */
}

body > iframe[style='2147483647'] {
  display: none;
}

html {
  overflow: hidden;
}
/*  */

.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}

.ant-avatar {
  background: rgb(178 195 229);
  color: black;
}

.img-to-send-container {
  position: absolute;
  height: 100px;
  bottom: 0;
  z-index: 1;
  display: flex;
  width: 100%;
}

.img-to-send {
  height: 100px;
  width: 100px;
  margin-right: 4px;
  position: relative;
}

.img-to-send > img {
  height: 100%;
  width: 100%;
}
.img-to-send > span {
  width: 25px;
  height: 25px;
  background: red;
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  top: 0;
}

.quill .ql-toolbar.ql-snow,
.quill .ql-container.ql-snow {
  border: 1px solid #e6ebf1;
}
.quill .ql-container.ql-snow {
  border-radius: 0 0 0.5rem 0.5rem;
}
.quill .ql-toolbar.ql-snow {
  border-radius: 0.5rem 0.5rem 0 0;
}
.quill * {
  color: #000;
}

.blueHoverText:hover {
  cursor: pointer;
  color: #3e79f7;
}
.menu-dark {
  color: #72849a;
}
.ant-input.leftFocus:focus {
  box-shadow: none;
}
.grayHover {
  background: rgb(247, 247, 248);
}
.grayHover:hover {
  background: rgb(237, 237, 238);
}

.event .ant-card-body {
  height: 100%;
}
.chart.ant-card {
  margin: 0;
}
.chart .ant-card-body {
  padding-bottom: 0;
}

.noteTextarea {
  font-size: 12px;
  border-radius: 0.5rem;
}

.ant-modal-wrap {
  overflow: hidden;
}
